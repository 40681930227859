.slotsAndPoints {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*padding: 1rem;*/
}

.slotsAndPoints .spacer {
    height: 1px;
    flex: 1;
}

.slotsAndPoints .restButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #00000000;
    border: none;
}

.spaceTop {
    margin-top: 1rem;
}

.levelModal {
    margin-top: 2rem;
    padding: 2rem;
    background-color: var(--c3);
    position: relative;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}

.levelModal button {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: .5rem;
    font-size: 1.5rem;

    background-color: var(--c4);
    color: white;
}

.levelModal button.disabled {
    color: #ffffff90;
    background-color: var(--c2);
}

.levelModal button.wide {
    width: 5rem;
    height: 3rem;
}

.levelModalWrapper,
.levelModalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.levelModalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.levelModalBackdrop {
    background-color: #00000080;
    z-index: 2;
}

.sorcPoints,
.levelSlots {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.foo {
    background-color: #640164;
}

.orb {
    margin-left: 4px;
}

/*POINTS*/

.sorcPoints {
    max-width: 80vw;
}

.sorcPointOrbs {
    flex-wrap: wrap;
}

.sorcPoints .label {
    margin-right: 12px;
}

/*SLOTS*/

.levelSlots {
    position: relative;
}

.levelSlots .label {
    position: absolute;
    top: 0;
    left: -1rem;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
