.App {
  text-align: center;
  /*padding: 1rem;*/

  --c1: #2f253d;
  --c2: #53476a;
  --c3: #7b6c93;
  --c4: #a864af;

  /*position: fixed;*/
  /*top: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/

  scroll-snap-type: x mandatory;
  width: 100vw;
  overflow-y: auto;
}

.contentWrapper {
  /*position: relative;*/
  /*width: 200px;*/
  display: flex;
  /*height: 100vh;*/
  /*overflow: hidden;*/
}

.page {
  position: relative;
  height: 100vh;
  width: 100vw;

  scroll-snap-align: start;
  scroll-snap-stop: always;

  /*width: 100vw;*/
  background-color: #312939;
  color: white;
}

.cornerNav {
  position: absolute;
  top: 0;
  right: 0;

  background-color: var(--c4);
  color: white;

  text-decoration: none;

  width: 100px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0 0 0 8px;
  z-index: 3;
}

.favouriteButton {
  background-color: #00000000;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.favouriteButton:not(.active) svg {
  opacity: .3;
}