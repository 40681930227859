
.search {
    display: flex;
    flex-direction: column;
}

.search .searchHeader {
    pointer-events: none;
    margin: 16px 0;
}

.search .filters,
.search .results {
    margin: 0 auto;
}

.search .filters {
    z-index: 1;
    flex: 1 auto;
    /*width: min(100vw - 10px, 470px);*/
}

.search .levelAndClass {
    max-height: 50vh;
    transition: max-height 200ms linear;
    /*overflow-y: hidden;*/
}

.search .hidden.levelAndClass {
    max-height: 0;
    overflow: hidden;
}

.search .results {
    flex: auto 50;
    overflow-y: auto;
    padding-bottom: 4rem;
    margin-top: -40px;
    padding-top: 40px;
    width: min(100vw - 10px, 800px);
}

.searchBox {
    position: relative;
    display: inline-block;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*margin: 0 auto;*/
}

.clearSearchBoxButton {
    background: none;
    border: none;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 0;
    right: -34px;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.clearSearchBoxButton svg {
    height: 20px;
    width: 20px;
    fill: white;
}

.input {
    background-color: unset;
    border: 1px solid white;
    color: white;
    padding: 6px 8px;
    font-size: 16px;
    border-radius: 4px;
    outline-width: 0;
}

#nameSearch {
    width: clamp(180px, 100vw - 200px, 280px);
}

#nameSearch::-webkit-search-cancel-button{
    display: none;
}

.addOrRemoveSpellButton {
    border: none;
    border-radius: 6px;
    height: 30px;
    margin-left: 4px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.addOrRemoveSpellButton svg {
    --size: 18px;
    min-height: var(--size);
    min-width: var(--size);
}

.search .addButton {
    background-color: var(--c2);
}
.removeButton {
    background-color: #893e64;

}

.search #spellsHeader {
    margin-bottom: -20px;
}

.search .levelAndClass {
    position: relative;
    margin-top: .75rem;
}

.filterFade {
    position: relative;
    height: 40px;
    width: 100vw;
    background-image: linear-gradient(#312939, #312939, #31293900);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.levelAndClass  .favouriteButton {
    position: absolute;
    left: 1rem;
    top: -2.5rem;
}
